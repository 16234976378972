import React, { useCallback, useState, useMemo, useEffect } from 'react'
import * as styles from './login.module.less'
import {
  login,
  LoginError,
  forgotPassword,
  LoginNavState,
  cognitoLogin,
  COGNITO_AUTH_URL,
  isCognito,
  logOut,
  fetchAndStoreToken
} from 'src/utils/auth'
import Button from '../button/button'
import Link from '../button/link'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/client'
import Panel from 'src/components/panel/panel'
import { Formik, Form, FastField } from 'formik'
import * as Yup from 'yup'
import FormErrorMessage from '../form-error-message/form-error-message'
import { TextField } from '@material-ui/core'
import Icon from '../icon/icon'
import { useLocation } from '@reach/router'
import { getClientConfig, getEnvConfig } from 'src/config/config'
import { useQueryParams, BooleanParam } from 'use-query-params'
import { H1, Body } from '../typography/typography'
import Spinner from '../spinner/spinner'

const isBrowser = typeof window !== 'undefined'

interface LoginFormData {
  email: string
  password: string
}

interface ForgotPasswordForm {
  email: string
}

const initialLoginValues: LoginFormData = { email: '', password: '' }
const initialForgotPasswordValues: ForgotPasswordForm = { email: '' }

const CLUBSPARK_CLASSIC_URL = getEnvConfig().CLUBSPARK_CLASSIC_URL
const RESET_PASSWORD_URL = CLUBSPARK_CLASSIC_URL
  ? `${CLUBSPARK_CLASSIC_URL}/Account/ForgottenPassword`
  : ''

const Login: React.FC = () => {
  const { t } = useTranslation()
  const [displayedScreen, setDisplayedScreen] = useState<'login' | 'forgotPassword'>('login')
  const onForgotPassword = useCallback(() => setDisplayedScreen('forgotPassword'), [
    setDisplayedScreen
  ])
  const onBack = useCallback(() => setDisplayedScreen('login'), [setDisplayedScreen])

  const logo = useMemo(() => {
    const { logoFilename, logoAltText } = getClientConfig()
    return {
      src: logoFilename && require(`src/images/${logoFilename}`)?.default,
      altText: logoAltText
    }
  }, [])

  return (
    <div className={styles.loginContainer}>
      {logo.src && <img className={styles.logo} src={logo.src} alt={logo.altText} />}
      {!logo.src && logo.altText && <H1 spacing={{ margins: { sm: 'bottom' } }}>{logo.altText}</H1>}
      <Panel>
        <div className={styles.formContainer}>
          <div className={styles.headingContainer}>
            {displayedScreen === 'forgotPassword' && (
              <button className={styles.backButton} onClick={onBack}>
                <Icon className={styles.backButtonIcon} name="sm-left" />
              </button>
            )}
            <h2 className={styles.signInTitle}>
              {displayedScreen === 'login' ? t('sign in') : t('forgotten password')}
            </h2>
            {displayedScreen === 'login' && isCognito() && (
              <span className={styles.loginInfo}>
                <Body p size="lg">
                  {t('sso login info', { client: getClientConfig().clientName })}
                </Body>
              </span>
            )}
          </div>
          {!isBrowser && <Spinner />}
          {isBrowser && displayedScreen === 'login' && (
            <LoginForm onForgotPassword={onForgotPassword} />
          )}
          {isBrowser && displayedScreen === 'forgotPassword' && <ForgotPasswordForm />}
        </div>
      </Panel>
      {/* <span className={styles.signUp}>
        Don't have an account? <Button linkStyle>Sign Up</Button>
      </span> */}
    </div>
  )
}

interface LoginFormProps {
  onForgotPassword: () => void
}

const LoginForm: React.FC<LoginFormProps> = ({ onForgotPassword }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [loginError, setLoginError] = useState<string>()
  const location = useLocation()
  const client = useApolloClient()
  const { t } = useTranslation()

  const onLogin = useCallback(
    async (vals: LoginFormData) => {
      const { email, password } = vals
      setLoading(true)
      setLoginError(undefined)

      const redirectUrl = (location.state as LoginNavState)?.redirectUrl
      const { errorCode } = await (isCognito()
        ? cognitoLogin({ client, redirectUrl })
        : login(email, password, client, redirectUrl))

      if (errorCode === LoginError.REQUIRES_COGNITO_LOGIN && isCognito()) {
        window.location.href = COGNITO_AUTH_URL
        return // so loading spinner remains
      }

      if (errorCode === LoginError.GENERIC) {
        setLoginError(t('generic login fail'))
      }
      if (errorCode === LoginError.NON_ADMIN) {
        setLoginError(t('not admin'))
      }
      if (errorCode === LoginError.INVALID_SCOPE) {
        setLoginError(t('invalid scope'))
      }
      setLoading(false)
    },
    [setLoading, setLoginError, client]
  )

  const [urlParams, setUrlParams] = useQueryParams({ auto: BooleanParam })
  useEffect(() => {
    if (urlParams.auto && isCognito()) {
      setUrlParams({ auto: undefined })
      ;(async () => {
        await fetchAndStoreToken()
        onLogin({ email: '', password: '' })
      })()
    }
  }, [urlParams, setUrlParams, onLogin])

  const validationSchema = useMemo(() => {
    if (isCognito()) {
      return Yup.object()
    }
    return Yup.object().shape({
      email: Yup.string().required(t('missing email')),
      password: Yup.string().required(t('missing password'))
    })
  }, [t])

  const onForgotPasswordClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      onForgotPassword?.()
    },
    [onForgotPassword]
  )

  return (
    <>
      <Formik {...{ validationSchema, onSubmit: onLogin, initialValues: initialLoginValues }}>
        {({ errors, touched }) => {
          return (
            <Form className={styles.loginForm}>
              {!isCognito() && (
                <>
                  <FastField
                    name="email"
                    component={OutlineTextField}
                    label={t('email', { defaultValue: 'Email' })}
                  />
                  {errors?.email && touched?.email && <FormErrorMessage message={errors.email} />}
                  <FastField
                    name="password"
                    component={OutlineTextField}
                    label={t('password', { defaultValue: 'Password' })}
                    type="password"
                  />
                  {errors?.password && touched?.password && (
                    <FormErrorMessage message={errors.password} />
                  )}
                  <div className={styles.actionsRow}>
                    {RESET_PASSWORD_URL ? (
                      <Link href={RESET_PASSWORD_URL} target="_blank">
                        {t('forgot password button')}
                      </Link>
                    ) : null}

                    {/* <Button size={'md'} linkStyle onClick={onForgotPasswordClick} type="button">
                      {t('forgot password button')}
                    </Button> */}
                  </div>
                </>
              )}

              <div>
                <Button
                  fluid
                  loading={loading}
                  spacing={{ margins: { md: 'vertical' } }}
                  size="lg"
                  type="submit"
                >
                  {t('login')}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>

      {loginError && (
        <div className={styles.loginError}>
          {loginError}
          {isCognito() && (
            <Button fluid spacing={{ margins: { sm: 'top' } }} linkStyle onClick={() => logOut()}>
              {t('sso sign out')}
            </Button>
          )}
        </div>
      )}
    </>
  )
}

const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [pwResetError, setPwResetError] = useState<string>()
  const [pwResetSuccess, setPwResetSuccess] = useState(false)

  const validationSchema = useMemo(
    () => Yup.object().shape({ email: Yup.string().required(t('missing email')) }),
    [t]
  )

  const onSubmit = useCallback(
    async (vals: ForgotPasswordForm) => {
      const { email } = vals
      setLoading(true)
      setPwResetError(undefined)
      const { errorCode } = await forgotPassword(email)
      if (errorCode === LoginError.NO_USER) {
        setPwResetError(t('forgot pw no user'))
      } else if (errorCode === LoginError.GENERIC) {
        setPwResetError(t('forgot pw failed'))
      } else if (errorCode === LoginError.NONE) {
        setPwResetSuccess(true)
      }
      setLoading(false)
    },
    [setLoading, setPwResetError, setPwResetSuccess]
  )

  return (
    <>
      {pwResetSuccess ? (
        <div className={styles.forgotPwSuccess}>
          <Icon className={styles.successIcon} name="md-tick-circle" />
          {t('forgot pw success')}
        </div>
      ) : (
        <Formik {...{ validationSchema, onSubmit, initialValues: initialForgotPasswordValues }}>
          {({ errors, touched }) => {
            return (
              <Form className={styles.loginForm}>
                <FastField name="email" component={OutlineTextField} label={t('email')} />
                {errors?.email && touched?.email && <FormErrorMessage message={errors.email} />}
                <div>
                  <Button
                    fluid
                    loading={loading}
                    spacing={{ margins: { md: 'vertical' } }}
                    size="lg"
                    type="submit"
                  >
                    {t('submit')}
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      )}
      {pwResetError && <div className={styles.loginError}>{pwResetError}</div>}
    </>
  )
}

interface OutlineTextFieldProps {
  field: {
    name: string
    value: string
  }
  type?: string
  label: string
  placeholder?: string
}

const OutlineTextField: React.FC<OutlineTextFieldProps> = ({ field, ...props }) => {
  return (
    // div wrapper to fix MUI SSR not rendering margins
    <div className={styles.textField}>
      <TextField
        {...props}
        {...field}
        variant="outlined"
        InputLabelProps={{ classes: { shrink: styles.textFieldLabel } }}
        InputProps={{
          classes: { root: styles.input, notchedOutline: styles.inputBorder }
        }}
      />
    </div>
  )
}

export default Login
