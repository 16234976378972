import React, { HTMLProps } from 'react'
import { Link as RouterLinkBase, GatsbyLinkProps as RouterLinkBaseProps } from 'gatsby'
import { SpacingProps, useSpacing } from 'src/hooks/spacing'
import { LevelProps, useLevelColour } from 'src/hooks/level-colour'
import cx from 'classnames'
import * as styles from './button.module.less'

interface LinkProps extends HTMLProps<HTMLAnchorElement>, SpacingProps {}

interface ButtonProps extends Omit<HTMLProps<HTMLAnchorElement>, 'size'>, LevelProps, SpacingProps {
  fluid?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

interface RouterLinkProps<TState> extends RouterLinkBaseProps<TState>, LevelProps, SpacingProps {
  fluid?: boolean
  size?: 'xs' | 'sm' | 'md' | 'lg'
}

const sizeClassMap = { xs: 'x-small', sm: 'small', md: 'medium', lg: 'large' }

const Link: React.FC<LinkProps> = ({ className, ...props }) => (
  <a {...props} className={cx(className, styles.button, styles.linkButton)} />
)

export const Button: React.FC<ButtonProps> = ({
  className,
  spacing,
  level = 'primary',
  size = 'md',
  fluid,
  ...props
}) => {
  const spacingClass = useSpacing(spacing)
  const lvlClass = useLevelColour(level, true)

  return (
    <a
      {...props}
      className={cx(
        className,
        styles.button,
        styles.link,
        spacingClass,
        styles[sizeClassMap[size]],
        lvlClass,
        {
          [styles.fluid]: fluid
        }
      )}
    />
  )
}

export const RouterLink: React.FC<RouterLinkProps<{}>> = ({
  className,
  spacing,
  level = 'primary',
  size = 'md',
  fluid,
  ...props
}) => {
  const spacingClass = useSpacing(spacing)
  const lvlClass = useLevelColour(level, true)

  return (
    <RouterLinkBase
      {...props}
      className={cx(
        className,
        styles.button,
        styles.link,
        spacingClass,
        styles[sizeClassMap[size]],
        lvlClass,
        {
          [styles.fluid]: fluid
        }
      )}
    />
  )
}

export default Link
